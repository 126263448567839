import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ProductGrid from "../components/ProductGrid"
import CTA from "../components/CTA"
import PageHeading from "../components/PageHeading"
import { Product } from "../types"

type TemplateProps = {
  page: {
    title: string
    subtitle: string
    support: string
    heroImage: string
    callToAction: {
      heading: string
      subHeading: string
      action: string
    }
  }
  products: Product[]
  selectedCategory?: string
}

export function ProductosPageTemplate({
  page,
  products,
  selectedCategory,
}: TemplateProps) {
  const { title, subtitle, support, heroImage, callToAction } = page
  return (
    <>
      <section className="about-hero bg-gradient-to-br from-green-300 via-green-500 to-green-600 md:pb-16">
        <div className="flex flex-col w-full max-w-5xl px-8 mx-auto mt-32 md:flex-row md:px-2 about-hero">
          <div className="flex flex-col w-full md:w-1/2">
            <p className="mt-6 text-lg font-semibold text-orange-500">
              {support}
            </p>
            <div className="mt-4">
              <PageHeading>{title}</PageHeading>
            </div>
            <p className="max-w-md mt-6 text-lg text-green-100">{subtitle}</p>
            <div className="mt-6">
              <CTA url="/contactanos">Solicita una cotización</CTA>
            </div>
          </div>
          <div className="flex-shrink w-full md:w-1/2">
            <img
              src={heroImage}
              alt="Imagen de varios granos"
              className="object-cover max-w-full mx-auto mt-16 w-128 md:w-auto md:max-w-md md:mx-0 md:mt-0"
              style={{ height: 512, width: 384 }}
            />
          </div>
        </div>
        <div className="bg-cut"></div>
      </section>

      <section className="w-full max-w-5xl mx-auto">
        <div className="px-2 -mx-6 md:px-2">
          <ProductGrid
            products={products}
            selectedCategory={selectedCategory}
          />
        </div>
      </section>
      <div className="Section__masked">
        <div className="Section__backgroundMask">
          <div className="Section__background"></div>
        </div>
        <div className="Section__container">
          <div className="w-full py-12 mt-12 mb-16 md:py-24">
            <div className="flex flex-col items-center w-full max-w-5xl px-2 mx-auto md:px-8 md:flex-row">
              <div className="flex-1">
                <h2 className="text-4xl font-bold leading-10 text-center text-orange-500 font-display md:text-left">
                  {callToAction.heading}
                </h2>
                <p className="mt-4 text-center text-gray-700 md:text-left">
                  {callToAction.subHeading}
                </p>
              </div>
              <div className="flex justify-end flex-1 mt-6 md:mt-0">
                <CTA url="/contactanos">{callToAction.action}</CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

type PageProps = {
  data: any
  pageContext: { selectedCategory: string }
}

export default function ProductosPage({ data, pageContext }: PageProps) {
  const { frontmatter: page } = data.pageData.edges[0].node
  const products = data.productData.edges.map(
    (edge: { node: { frontmatter: any } }) => edge.node.frontmatter
  )

  const title = pageContext.selectedCategory || page.seo.title

  return (
    <Layout>
      <SEO title={title} description={page.seo.description} />
      <ProductosPageTemplate
        page={page}
        products={products}
        selectedCategory={pageContext.selectedCategory}
      />
    </Layout>
  )
}

export const productosPageQuery = graphql`
  query ProductosPageQuery {
    productData: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "product" }, available: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            category
            name
            provenance
            image
            packages
          }
        }
      }
    }
    pageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "productos-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            support
            heroImage
            callToAction {
              heading
              subHeading
              action
            }
            seo {
              browserTitle
              title
              description
            }
          }
        }
      }
    }
  }
`
