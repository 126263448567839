import React, { ReactElement, useState } from "react"
import cx from "classnames"
import { Product } from "../types"

type Props = {
  products: Product[]
  limit?: number
  selectedCategory?: string
  onTabChange?: (category: string) => void
}
const priorities = {
  "Frutos secos": 10,
  Granos: 20,
  Semillas: 30,
  "Materias primas": 40,
  Empacados: 50,
  Procesados: 60,
  Otros: 70,
}

export default function ProductGrid({
  products = [],
  limit,
  selectedCategory,
  onTabChange,
}: Props): ReactElement {
  const categories = products ? products.map(product => product.category) : []

  const uniqueCategories = [...new Set(categories)]
  const sortedCategories = uniqueCategories.sort(
    (a, b) => priorities[a] - priorities[b]
  )

  const [tab, setTab] = useState(selectedCategory || sortedCategories[0])

  let filteredProducts = products.filter(product => product.category === tab)
  if (limit) {
    filteredProducts = filteredProducts.slice(0, limit)
  }

  const handleTabChange = category => {
    setTab(category)

    if (onTabChange && typeof onTabChange === "function") {
      onTabChange(category)
    }
  }

  return (
    <>
      <div className="px-4">
        <div className="flex flex-row justify-start space-x-2 overflow-x-auto md:justify-center md:space-x-6">
          {uniqueCategories.map((category, index) => {
            const isSelected = category === tab
            return (
              <button
                key={index}
                onClick={() => handleTabChange(category)}
                className={cx("py-2 md:text-lg px-1 font-semibold ", {
                  "text-green-400 border-b-4 border-orange-400": isSelected,
                  "text-gray-600": !isSelected,
                })}
                style={{
                  transition: "opacity .35s cubic-bezier(.165,.84,.44,1)",
                }}
              >
                {category}
              </button>
            )
          })}
        </div>
      </div>
      <div className="w-full pb-2 mx-auto mt-4">
        <div>
          <div className="flex flex-wrap -mx-4">
            {filteredProducts.map((product: Product, index: number) => (
              <div
                key={index}
                className="flex flex-col items-center flex-1 px-1 pb-6 md:px-4 product-item"
                style={{ width: "calc((1155px - 100%) * 999)" }}
              >
                <div className="flex flex-col flex-1">
                  <div className="rounded-md">
                    <img
                      src={product.image}
                      alt={product.name}
                      loading="lazy"
                      className="object-cover w-full h-48 rounded-md"
                    />
                  </div>
                  <div className="mt-2">
                    <h3 className="font-bold leading-5 text-green-500 md:text-lg">
                      {product.name}
                    </h3>
                    <hr className="w-16 mt-1 mb-2 border-b-4 border-orange-500"></hr>
                    <p className="font-semibold text-gray-600">
                      {product.provenance}
                    </p>
                  </div>
                  <div className="mt-auto">
                    <div className="flex flex-wrap -mx-1 -mt-2">
                      {product.packages.map((p: string, index: number) => (
                        <div key={index} className="px-1 pt-2">
                          <div className="inline-flex flex-1 px-3 py-2 text-sm font-semibold text-gray-700 bg-gray-200 shadow">
                            {p}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
